<template lang="pug">
section.compliance(
    v-if="complianceElement"
    v-html="complianceElement.html"
    :style="complianceElement.style"
)
</template>

<script>
export default {
    name: 'Compliance',

    props: {
        type: {
            type: String,
            required: true,
        },
        config: {
            type: Object,
            required: true,
        }
    },

    computed: {
        complianceElement() {
            if (!['header', 'footer'].includes(this.type)) return undefined;

            const data = (this.config.compliance || {})[this.type];
            if (!data || !data.text) return undefined;

            return { style: this.getStyleString(data), html: data.text };
        }
    },

    methods: {
        getStyleString(data) {
            const borderWidth = (data.custom_colors ? (data.border_pixels || 1) : 1) + 'px';
            const primaryColor = this.config?.theme?.button_background_color;
            const style = {
                border: `solid ${borderWidth} #DDD`,
                color: '#333',
                'background-color': '#FFF',
                '--link-color': primaryColor || '#0066CC',
            };
            if (data.custom_colors) {
                if (data.border_color) { style.border = `solid ${borderWidth} ${data.border_color}`; }
                if (data.text_color) { style.color = data.text_color; }
                if (data.background_color) { style['background-color'] = data.background_color; }
                if (data.link_color) { style['--link-color'] = data.link_color; }
            }
            return Object.entries(style).map(([k, v]) => `${k}:${v}`).join(';');
        }
    }
}
</script>
