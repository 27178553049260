<template lang="pug">
.registration-card(v-if="enabled")
    template(v-if="canRegister")
        h2 {{ registerTitle }}

        p(v-if="showWaitingListWarning") {{ $t("registration.form.waiting_list_warning") }}

        validation-observer(v-if="!emailAuthDisabled" v-slot="{ handleSubmit }" :slim="true"): form(@submit.prevent="handleSubmit(submit)")
            validation-provider(:rules="validators" mode="passive" :slim="true" v-slot="{ errors }" name="email")
                base-input.actionable(
                    v-model="email"
                    type="email" name="email"
                    :errors="errors"
                    :placeholder="$t(`registration.form.${retrievingReg? 'retrieve_reg_placeholder' : 'email_placeholder'}`)"
                    @blur="trim")
                    button.btn.btn-medium.btn-primary {{ $t(`registration.form.${retrievingReg? 'retrieve_btn_text' : 'submit'}`) }}

        a.btn.btn-tertiary.retrieve-reg-back(v-if="retrievingReg" @click.prevent="retrievingReg = false") {{$t('login.back')}}

        .retrieve-reg(v-if="showRetrieveReg && !retrievingReg && !emailAuthDisabled")
            span {{ $t('registration.form.already_registered') }}
            a(@click.prevent="retrievingReg = true") {{ $t('registration.form.retrieve_your_reg') }}

        .separator(v-if="!emailAuthDisabled && ssoProviders.length"): span {{ $t('registration.base.or') }}

        .sso(v-if="ssoProviders.length && !retrievingReg")
            a.btn.btn-medium.important(v-for="ssoProvider in ssoProviders"
                :class="emailAuthDisabled ? 'btn-primary' : 'btn-default'"
                :key="ssoProvider.id"
                :title="$t('registration.form.sso_button_title', [ssoProvider.label])"
                :href="ssoRegUrl(ssoProvider.url)")
                | {{ $t('registration.form.sso_button_title', [ssoProvider.label]) }}

        disclaimers(:requirements="config.event_details.requirements")

    template(v-if="showRegClosed")
        h2 {{ $t('registration.form.closed_title') }}
        p {{ $t('registration.form.closed_text') }}

    template(v-if="showForbidden")
        i18n.forbidden(path="registration.form.private_reg_forbidden_text" tag="p")
            a(:href="supportUrl" target="_blank" rel="noopener noreferrer") {{ $t('registration.form.private_reg_support') }}

    template(v-if="showUserLimitReached")
        h2 {{ $t('registration.form.user_limit_reached_title') }}
        p {{ $t('registration.form.user_limit_reached_text') }}
</template>
<script>
// Utils
import { get } from 'lodash';

// Components
import Disclaimers from '@/components/registration/Disclaimers.vue';
import BaseInput from '@/components/form-elements/BaseInput.vue';

export default {
    name: 'RegistrationCard',

    components: { BaseInput, Disclaimers },

    props: {
        config: {
            type: Object,
            required: true
        },

        session: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        const userLimmitReached = this.$services.publicLogin.isUserLimmitReached(this.config);
        return {
            canRegister: this.$services.publicLogin.canRegister(this.config) && !userLimmitReached,
            userLimmitReached,
            email: get(this.config, 'user.email', '') || get(this.session, 'account', '') || get(this, '$route.query.email', ''),
            emailAuthDisabled: this.$services.publicLogin.isEmailAuthDisabled(this.config),
            ssoProviders: this.$services.publicLogin.getSsoProviders(this.config),
            validators: {
                required: true,
                email_domain: {
                    eventId: this.config.event_details._id,
                    settings: this.config.settings
                }
            },
            showRetrieveReg: !!this.config.event_details.use_registration_pin_code,
            retrievingReg: false,
            forbidden: false
        };
    },

    computed: {
        enabled() {
            return this.canRegister || this.showRegClosed || this.showForbidden || this.showUserLimitReached;
        },

        showRegClosed() {
            const isUserUpdating = this.$services.publicLogin.isUpdating(this.config);
            const isPubRegClosed = !this.$services.publicLogin.canRegister(this.config);
            const isPrivateReg = this.$services.publicLogin.isPrivateReg(this.config);
            return !isUserUpdating && isPubRegClosed && !isPrivateReg;
        },

        showForbidden() {
            const isUserUpdating = this.$services.publicLogin.isUpdating(this.config);
            const isPrivateReg = this.$services.publicLogin.isPrivateReg(this.config);
            return this.forbidden || (!isUserUpdating && isPrivateReg);
        },

        showUserLimitReached() {
            return !this.showRegClosed && !this.showForbidden && this.userLimmitReached;
        },

        showWaitingListWarning() {
            return this.$services.publicLogin.displayWaitingList(this.config);
        },

        registerTitle() {
            if (this.retrievingReg) {
                return this.$t('registration.form.retrieve_your_reg');
            }
            if (this.showWaitingListWarning){
                return this.$t('registration.form.waiting_list_title');
            }
            return this.$t('registration.form.title');
        },

        supportUrl() {
            const url = new URL(process.env.supportFormUrl);
            url.searchParams.append('eid', this.config.event_details._id);
            return url.toString();
        }
    },

    methods: {
        async submit() {    
            const action = await this.$services.publicLogin.getLoginAction(this.config.event_details._id, this.email);
            if (action === 'forbiden' || (action !== 'pin' && action !== 'form')) {
                this.forbidden = true;
                return;
            }
            const pageName = action === 'pin' ? 'registration-pin' : 'registration-form';
            this.$router.push({
                name: pageName,
                query: {
                    ...this.$route.query,
                    email: this.email,
                    source: 'registration',
                }
            });
        },

        trim() {
            console.debug('[RegistrationCard] Trimming extra spaces');
            this.email = this.email.trim();
        },

        ssoRegUrl(endpoint) {
            try {
                const url = new URL(endpoint);
                url.searchParams.append('registration', this.$route.params.id);

                Object.keys(this.$route.query).forEach(q => {
                    url.searchParams.append(q, this.$route.query[q]);
                });

                return url.toString();
            } catch (error) {
                console.warn('[RegistrationCard] Could not properly build url', error);
                const separator = endpoint.includes('?') ? '&' : '?';
                return `${endpoint}${separator}registration=${this.$route.params.id}`;
            }
        }
    }
}
</script>
