<template lang="pug">
form.pin-input(@submit.prevent="validatePin")
    .pin-card
        h2 {{ $t('registration.pin.title') }}

        p {{ $t('registration.pin.description') }}

        .resend-container
            span {{ $t('registration.pin.did_not_receive') }}
            a(@click.prevent="resendPinCodeEmail") {{ $t('registration.pin.send_again') }}
            span.success-message(v-if="emailReSent")
                i.icon-check
                | {{ $t('registration.pin.email_sent_again') }}

        input.form-control(
            v-for="(digit, index) in internalValue"
            v-model="internalValue[index]"
            type="text"
            inputmode="numeric"
            pattern="[0-9]"
            maxlength="1"
            placeholder="_"
            ref="digit"
            required
            @input="dataChange(index)"
            @keydown="(event) => handleSpecialKeys(index, event)"
            @paste="onPaste"
            :name="`${name}[${index}]`"
            :class="{error: error}"
        )

        p.error(v-if="emailReSentError")
            i.icon-cross {{ $t('registration.pin.email_sent_error') }}
        .error(v-if="error") {{ $t('registration.pin.error') }}

    .form-actions
        button.btn.btn-medium(@click.prevent="back") {{ $t('registration.actions.previous_section') }}
        button.btn.btn-primary.btn-medium(
            :disabled="pin === ''"
        ) {{ $t('login.submit') }}
</template>
<script>
const DIGITS = 8;

export default {
    name: 'PinInput',

    props: {
        config: {
            type: Object,
            required: true
        },

        value: {
            type: [String],
            default: null
        },

        label: {
            type: String,
            required: false,
            default: null,
        }
    },

    data() {
        return {
            pin: '',
            internalValue: Array(DIGITS),
            error: false,
            email: this.$route.query.email,
            emailReSent: false,
            emailReSentError: false,
        };
    },

    watch: {
        value(newValue) {
            const valueArray = Array.from(newValue || '');
            this.internalValue = Array.from(Array(DIGITS)).map((_, i) => valueArray[i] || null);
        },
    },

    methods: {
        dataChange(index) {
            if (this.internalValue[index] && index + 1 < this.internalValue.length) {
                this.$nextTick(() => {
                    this.$refs.digit[index + 1].focus();
                });
            }
            // can't use every as every skips empty slot
            for (const digit of this.internalValue) {
                if (!/\d/.test(digit)) {
                    this.pin = '';
                    return;
                }
            }
            this.pin = this.internalValue.join('');
            // reset error state
            if (this.pin.length) {
                this.error = false;
            }
        },

        handleSpecialKeys(index, event) {
            if (event.key === 'Backspace' && !this.internalValue[index] && index > 0) {
                this.internalValue[index - 1] = '';
                this.$nextTick(() => {
                    this.$refs.digit[index - 1].focus();
                });
            }
            if (event.key === 'ArrowRight' && index + 1 < this.internalValue.length) {
                this.$nextTick(() => {
                    this.$refs.digit[index + 1].focus();
                });
            }
            if (event.key === 'ArrowLeft' && index > 0) {
                this.$nextTick(() => {
                    this.$refs.digit[index - 1].focus();
                });
            }
        },

        async resendPinCodeEmail() {
            // here we reuse the same method as in the registration form
            // to re-send the pin code email
            const action = await this.$services.publicLogin.getLoginAction(this.config.event_details._id, this.email);
            if (action === 'pin') {
                this.emailReSent = true;
                setTimeout(() => {
                    this.emailReSent = false;
                }, 5000);
            } else {
                this.emailReSentError = true;
            }
        },

        back() {
            if (this.$route.query.source === 'reg-form') {
                this.$router.push({ name: 'registration-form', query: {
                    ...this.$route.query,
                    email: this.$route.query.email,
                    source: 'pin',
                }});
                return;
            }
            this.$router.push({ name: 'registration' });
        },

        async validatePin() {
            const url = await this.$services.publicLogin.validatePin(this.config.event_details._id, this.email, this.pin);
            if (url) {
                window.location.replace(url);
            } else {
                this.error = true;
            }
        },

        onPaste(e) {
            e.preventDefault();
            const data = e.clipboardData?.getData('text');
            if (!data) return;
            if (data.length === DIGITS && Number.isInteger(Number(data))) {
                this.internalValue = Array.from(data).map(Number);
                this.dataChange();
            }
        }
    },
};
</script>
