<template lang="pug">
.sign-up
    main(ref="main")
        main-banner(:config="config")

        .container.page-wrapper: .inner-wrapper
            event-card(:config="config")
            pin-input(:config="config")
            disclaimers(:requirements="config.event_details.requirements")
</template>
<script>

// Components
import Disclaimers from '@/components/registration/Disclaimers.vue';
import EventCard from '@/components/registration/EventCard.vue';
import MainBanner from '@/components/layout/MainBanner.vue';
import PinInput from '@/components/registration/PinInput.vue';

export default {
    name: 'EnterPin',

    components: {
        Disclaimers,
        EventCard,
        MainBanner,
        PinInput
    },

    props: {
        config: {
            type: Object,
            required: true
        },

        session: {
            type: Object,
            required: true
        },

        editing: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {};
    },

    methods: {}
};
</script>
