<template lang="pug">
.registration-forms
    generic-form(
        v-if="currentForm"
        @input="onInput"
        @change="onChange"
        @prev="onPrev"
        :key="currentForm.name"
        :config="config"
        :form="currentForm.form"
        :settings="config.settings"
        :event-id="config.event_details._id"
        :orchestrators="{ 'sessions-registration': regOrchestrator }"
        :disabled="disabled"
        :errors="errors"
        :name="currentForm.name"
        :value="values[currentForm.name]"
        :action-label="actionLabel"
        :show-prev-button="Boolean(previousForm)"
        :title="$te(`registration.form.titles.${currentForm.name}`) ? $t(`registration.form.titles.${currentForm.name}`) : null"
        :editing="editing"
        portal="sign-up-root")
</template>
<script>
// Utils
import SessionsRegistrationOrchestrator from '@/libs/utils/sessions-registration-orchestrator';

// Components
import GenericForm from '@/components/form-elements/GenericForm.vue';

/**
 * @typedef {{ name: string, value: string }[][]} Conditions
 *
 * @typedef {object} Field
 * @prop {string} label
 * @prop {string} name
 * @prop {boolean} required
 * @prop {number} order
 * @prop {string} [kind]
 * @prop {any} [kind_options]
 * @prop {boolean} [removable]
 *
 * @typedef {object} Form
 * @prop {Field[]} fields
 * @prop {string} name
 * @prop {number} order
 * @prop {Conditions} [conditions]
 *
 * @typedef {{ name?: string, form?: Form }} FormWithName
 */

/**
 * Vue component for the registration forms.
 *
 * This component handles the registration process by rendering a series of forms
 * and submitting the form data to the server. It also handles form validation and error handling.
 *
 * @component
 * @example
 * <RegistrationForms :config="registrationConfig" :editing="false" />
 *
 * @param {Object} config - The configuration object for the registration forms.
 * @param {boolean} editing - Flag indicating whether the forms are in editing mode.
 */
export default {
    name: 'RegistrationForms',

    components: { GenericForm },

    props: {
        config: {
            type: Object,
            required: true
        },

        editing: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            /** @type {FormWithName} */
            currentForm: null,
            nextForm: {},
            previousForm: undefined,
            disabled: false,
            errors: null,
            forms: [],
            values: {},
            regOrchestrator: new SessionsRegistrationOrchestrator(this.$services, this.config, this.$i18n.locale),
            paymentSuccess: false
        };
    },

    watch: {
        currentForm(newForm, oldForm) {
            if (oldForm) {
                this.$emit('form-change');
            }
        }
    },

    computed: {
        /** @returns {string} */
        actionLabel() {
            const completeKey = 'registration.actions.complete_registration';
            const continueKey = 'registration.actions.continue_registration';
            const updateKey = 'registration.actions.update_registration';
            const submitKey = this.editing ? updateKey : completeKey;

            return this.nextForm ? continueKey : submitKey;
        },

        /** @returns {boolean} */
        isPaymentEnabled() {
            return !!this.config.settings.payment_enabled;
        },

        /** @returns {string} */
        supportUrl() {
            const url = new URL(process.env.supportFormUrl);
            url.searchParams.append('eid', this.config.event_details._id);
            return url.toString();
        }
    },

    mounted() {
        if (this.isPaymentEnabled) {
            console.info('[RegistrationForms] Payment is enabled, loading script and then initializing');
            this.loadVivenuScript();
        } else {
            this.init();
        }
    },

    methods: {
        async init() {
            await this.regOrchestrator.init(Boolean(this.$route.query.debug));
            const { forms, values } = this.prepareForms();
            this.forms = forms;
            this.values = values;
            this.currentForm = forms[0];

            const userData = this.getUserData(values);
            this.nextForm = this.getAdjacentForm(forms, this.currentForm, userData, true);
            console.debug('[RegistrationForms] Initialized', this.forms, this.values);
        },

        /**
         * Prepares the registration forms.
         *
         * Creates an array of forms and an object with values by form
         * - _hidden_fields are added to the first form
         * - user data is added to values where applicable
         *
         * @returns {Object} An object containing the prepared forms and values.
         */
        prepareForms() {
            const { _hidden_fields, ...rest } = this.config.forms;
            const user = this.config.user || {};
            const main = this.hideRSVPStatus(rest);

            const { forms, values } = this.getFormsAndValues(rest, user);

            forms.sort((a, b) => (a.form.order || 0) - (b.form.order || 0));

            this.addHiddenFields(forms, _hidden_fields);

            this.setUserLocaleField(main, values);
            this.setDefaultRSVPStatus(main, values);

            return { forms, values };
        },

        /**
         * Hides the RSVP status field in the registration form.
         *
         * @param {Object} rest - The object containing the registration forms.
         * @param {Object} rest.main - The main object containing the registration form data.
         *
         * @returns {Object} - The updated main object with the RSVP status field hidden.
         */
        hideRSVPStatus(rest) {
            const main = rest.main;
            const hideRSVPStatus = !this.$services.publicLogin.isUpdating(this.config) && main?.fields.find(field => field.name === 'fp_rsvp_status');
            if (hideRSVPStatus) {
                rest.main = {
                    ...main,
                    fields: main.fields.filter(field => field.name !== 'fp_rsvp_status')
                };
            }
            return rest.main;
        },

        /**
         * Retrieves the forms and values based on the provided parameters.
         *
         * @param {Object} rest - Additional forms to be included.
         * @param {Object} user - User object containing the values to be populated in the forms.
         *
         * @returns {Object} - An object containing the forms and their corresponding values.
         */
        getFormsAndValues(rest, user) {
            const forms = [];
            const values = {};

            for (const [key, form] of Object.entries(rest)) {
                const formObj = { name: key, form };
                forms.push(formObj);
                values[key] = {};

                for (const { name, type } of form.fields) {
                    if (name in user) {
                        values[key][name] = user[name];
                    } else if (type === 'sessions') {
                        values[key][name] = this.regOrchestrator.getAllSelectedIds();
                    }
                }
            }

            return { forms, values };
        },

        /**
         * Adds hidden fields to the registration forms.
         *
         * @param {Array} forms - The array of registration forms.
         * @param {Object} _hidden_fields - The object containing the hidden fields.
         */
        addHiddenFields(forms, _hidden_fields) {
            if (_hidden_fields && Array.isArray(_hidden_fields.fields)) {
                const firstFormFieldNames = forms[0].form.fields;
                for (const field of _hidden_fields.fields) {
                    if (!firstFormFieldNames.includes(field)) {
                        forms[0].form.fields.push(field);
                    }
                }
            }
        },

        /**
         * Sets the user locale field in the main object.
         * If the 'fp_locale' field exists in the main object, it sets its value to the current locale.
         *
         * @param {Object} main - The main object.
         * @param {Object} values - The values object.
         */
        setUserLocaleField(main, values) {
            if (main?.fields.find(field => field.name === 'fp_locale')) {
                values.main.fp_locale = this.$i18n.locale;
            }
        },

        /**
         * Sets the default RSVP status for the registration form.
         * If the 'fp_rsvp_status' field is present in the 'main' object, it sets the value to 'yes'.
         * This is done to ensure that the 'fp_rsvp_status' field always has a default value of 'yes' even if it is removed from the form.
         *
         * @param {Object} main - The main object containing the form fields.
         * @param {Object} values - The values object containing the form values.
         */
        setDefaultRSVPStatus(main, values) {
            // As fp_rsvp_status is required, a default to yes is added in case it is removed from the form
            if (!this.$services.publicLogin.isPublicReg(this.config) && !main?.fields.find(field => field.name === 'fp_rsvp_status')) {
                values.main.fp_rsvp_status = 'yes';
            }
        },

        /**
         * Retrieves user data from the provided values.
         *
         * @param {Object} values - The values object containing form data.
         *
         * @returns {Object} - The user data object.
         */
        getUserData(values) {
            let currentData = this.config.user || {};
            for (const form of this.forms) {
                if (this.matches(currentData, form)) {
                    Object.assign(currentData, values[form.name]);
                }
            }
            return { self_registered: true, ...currentData };
        },

        /**
         * Handles the input event for the registration form.
         *
         * @param {Record<string, any>} formValues - The form values object.
         */
        onInput(formValues) {
            this.values = {
                ...this.values,
                [this.currentForm.name]: formValues

            };
            this.setPrevNext();
        },

        /**
         * Handles the change event for the registration form.
         *
         * If the next form is available, it sets the current form to the next form and updates the prev/next buttons.
         * If the next form is not available, it finalizes the registration
         *
         * @async
         * @function onChange
         * @memberof RegistrationForms
         * @throws {Error} If an error occurs during the action.
         *
         * @returns {Promise<void>}
         */
        async onChange() {
            const values = this.getUserData(this.values);

            // First check if the user exists if the entered email is not the same as the one in the query
            if (!this.$route.query.email || values.email !== this.$route.query.email) {
                const action = await this.$services.publicLogin.getLoginAction(this.config.event_details._id, values.email);
                if (action === 'pin') {
                    this.$router.push({
                        name: 'registration-pin',
                        query: {
                            ...this.$route.query,
                            email: values.email,
                            source: 'reg-form',
                        }
                    });
                    return;
                }
                if (action === 'forbidden' || (action !== 'pin' && action !== 'form')) {
                    this.handleRegistrationError({ response: { data: { name: 'RegistrationForbiddenError' }}});
                    return;
                }
            }

            try {
                this.disabled = true;

                if (this.nextForm) {
                    this.currentForm = this.nextForm;
                    this.setPrevNext();
                    this.reconcileFields(this.previousForm);
                } else if (this.isPaymentEnabled) {
                    this.openPaymentStep();
                } else {
                    this.errors = null;

                    await this.finalizeRegistration(values);
                }
            } catch (error) {
                console.error('[RegistrationForms] Could not perform the action:', error.message);
                this.handleRegistrationError(error);
            } finally {
                this.disabled = false;
            }
        },

        /**
         * Handles finalization of registration by submiting the form and redirecting if necessary
         * @param {Object} values - The form values.
         * @returns {Promise<void>}
         */
        async finalizeRegistration(values) {
            const data = await this.submitRegistration(values);
            console.info('[RegistrationForms] Action success:', data);
            const { action, sendingEmail, redirect } = data;

            if (redirect) {
                this.$services.routing.redirect(redirect);
                return;
            }

            this.$router.push({
                name: 'registration-end',
                params: {
                    branding: this.$route.params.branding,
                    id: this.$route.params.id,
                    end: action,
                    emailSent: sendingEmail ? '1' : '0',
                }
            });
        },

        /**
         * Submits the registration form.
         *
         * @param {Object} values - The form values.
         *
         * @returns {Promise} - A promise that resolves when the registration is submitted.
         */
        async submitRegistration(values) {
            console.info('[RegistrationForms] Submitting', values);

            const { branding } = this.$route.params;
            const eventId = this.config.event_details._id;
            return await this.$services.publicLogin.registerAccount(branding, eventId, values);
        },

        /**
         * Loads the Vivenu script.
         */
        loadVivenuScript() {
            const vivenuScript = document.createElement('script');

            vivenuScript.setAttribute('src', 'https://vivenu.dev/web/deliver/js/v1/embed.js');
            vivenuScript.setAttribute('async', true);
            vivenuScript.onload = this.init;

            document.head.appendChild(vivenuScript);
        },

        /**
         * Opens a new window with
         */
        async openPaymentStep() {
            const vivenuEventId = this.config.settings.payment_event_id;
            if (!vivenuEventId) {
                return this.finalizeRegistration();
            }

            window.VIVENU.onCheckoutCompleted = this.handleCheckoutSuccess;

            window.addEventListener(
                'message',
                this.handleCheckoutClose,
                false,
            );

            // TODO: use either dev or prod link (at the moment only dev account exists)
            const baseVivenuUrl = 'https://vivenu.dev';
            const eventId = this.config.event_details._id;
            const userData = this.getUserData(this.values);

            try {
                await this.$services.publicLogin.triggerPaymentStep(eventId, userData.email);

                this.disabled = true;

                window.VIVENU.showCheckoutModal({
                    id: vivenuEventId,
                    embedded: false,
                    baseUrl: baseVivenuUrl
                });
            } catch (err) {
                console.warn('Failed to trigger payment', err);
                this.disabled = false;
            }
        },

        /**
         * a callback triggered on checkout success (after payment)
         * @param {object} checkout - An object that contains information about the completed checkout process
         */
        handleCheckoutSuccess(checkout) {
            console.log('handleCheckoutSuccess: ', checkout);
            const userData = this.getUserData(this.values);

            if (checkout.email !== userData.email) { return; }

            this.paymentSuccess = true;
        },

        /**
         * triggered on checkout modal close
         * @param {object} event - the event object
         */
        handleCheckoutClose(event) {
            // do we trust this message?
            if (event.origin !== window.location.origin) { return; }

            // we only want to react to vivenu close modal message
            if (event.data !== 'close') { return; }

            this.disabled = false;

            if (this.paymentSuccess) {
                this.finalizeRegistration();
            } else {
                this.handleRegistrationError({ response: { data: { name: 'PaymentFailedError' }}});
            }
        },

        /**
         * Handles the registration error.
         *
         * @param {import('axios').AxiosError} error - The error object.
         */
        handleRegistrationError(error) {
            if (error.response) {
                const { status, data } = error.response;
                console.error('[RegistrationForms] An error occurred', status, data);

                const ERROR_MAP = {
                    DocumentLimitExceededError: 'registration.form.messages.user_limit_exceeded',
                    PaymentExpiredError: 'registration.form.messages.payment_time_expired',
                    PaymentFailedError: 'registration.form.messages.payment_failed',
                    RegistrationForbiddenError: 'registration.form.private_reg_forbidden_text_no_link',
                };

                if (ERROR_MAP[data.name]) {
                    this.errors = ERROR_MAP[data.name];
                } else if (status === 422) {
                    this.resetSessionRegistrationsSelection(data);
                    this.errors = data;
                } else {
                    this.errors = error.message;
                }
            }
        },

        /**
         * Go to the previous form in the registration process.
         */
        onPrev() {
            if (this.previousForm) {
                this.currentForm = this.previousForm;
                this.setPrevNext();
                this.reconcileFields(this.nextForm);
            }
        },

        /**
         * Sets the previous and next form based on the current form and user data.
         */
        setPrevNext() {
            const userData = this.getUserData(this.values);
            this.nextForm = this.getAdjacentForm(this.forms, this.currentForm, userData, true);
            this.previousForm = this.getAdjacentForm(this.forms, this.currentForm, userData, false);
        },

        /**
         * Reconciles the current fields with the same names with all
         * the previously collected info.
         *
         * @param {FormWithName} adjacent - The adjacent form object.
         */
        reconcileFields(adjacent) {
            const current = this.currentForm;

            for (const fieldC of current.form.fields) {
                if (!fieldC.name) continue;

                for (const fieldA of adjacent?.form.fields || []) {
                    if (!fieldA.name || fieldC.name !== fieldA.name ) continue;

                    const currentValue = this.values[this.currentForm.name][fieldC.name];
                    const adjacentValue = this.values[adjacent.name][fieldA.name];

                    if (adjacentValue !== currentValue) {
                        console.debug(`[RegistrationForms] Reconciling ${current.name}.${fieldC.name} to ${adjacent.name}.${fieldA.name}`);
                        this.$set(this.values[this.currentForm.name], fieldC.name, adjacentValue);
                    }
                }
            }
        },

        /**
         * Retrieves the next or previous form from the given array of forms based on the current form and main values.
         *
         * @param {FormWithName[]} forms - The array of forms.
         * @param {FormWithName} currentForm - The current form object.
         * @param {Record<string,any>} mainValues - The main values object.
         * @param {boolean} isNext - Indicates whether to retrieve the next form (true) or the previous form (false).
         *
         * @returns {FormWithName | undefined} - The next or previous form object, or undefined if not found.
         */
        getAdjacentForm(forms, currentForm, mainValues, isNext) {
            const currentIndex = forms.map(d => d.name).indexOf(currentForm.name);
            let adjacentForm;
            const increment = isNext ? 1 : -1;
            for (let i = currentIndex + increment; isNext ? i < forms.length : i >= 0; i += increment) {
                if (this.matches(mainValues, forms[i])) {
                    adjacentForm = forms[i];
                    break;
                }
            }
            return adjacentForm;
        },

        /**
         * Checks if the "main" form match the given conditions.
         *
         * @param {Record<string,any>} mainValues - The main values to compare.
         * @param {FormWithName} form - The form object containing the conditions.
         *
         * @returns {boolean} - Returns true if the mainValues match the conditions, otherwise false.
         */
        matches(mainValues, { form: { conditions } }) {
            if (!conditions) { return true }
            return Boolean(conditions.find(
                condition => condition.every(({ name, value }) => mainValues[name] === value)
            ))
        },

        /**
         * Resets the session registrations selection.
         *
         * @param {Object} errorData - The error data.
         */
        resetSessionRegistrationsSelection(errorData) {
            const selectedIds = this.regOrchestrator.restoreOriginalSelection(true, errorData);

            for (const formConfig of this.forms) {
                const { name, form } = formConfig;

                for (const block of form.fields.filter(field => field.type === 'sessions')) {
                    if (!errorData.hasOwnProperty(block.name)) {
                        continue;
                    }

                    this.values[name][block.name] = [];

                    for (const id of selectedIds) {
                        if (block.fp_ext_ids.includes(id)) {
                            this.values[name][block.name].push(id);
                        }
                    }
                }
            }
        }
    }
}
</script>
