module.exports={
    "a11y": {
        "actions": {
            "close": "Close"
        }
    },
    "account": {
        "account_reset_password": {
            "cancel": "Cancel",
            "confirm_password": "Confirm password",
            "email_label": "Email",
            "email_placeholder": "Your email",
            "old_password": "Old Password",
            "old_password_placeholder": "Your old password",
            "password": "Password",
            "password_placeholder": "Your password",
            "save_password": "Save new password",
            "subtitle": "In the field below type the email address with wich you have signed up, we will send you the instructions for you to restore your password.",
            "title": "Restore password"
        }
    },
    "activation": {
        "account_login_screen": {
            "email_label": "Email",
            "email_placeholder": "Your email address",
            "fname_label": "First name",
            "fname_placeholder": "Your first name",
            "lname_label": "Last name",
            "lname_placeholder": "Your last name",
            "magic_link_hint": "We will send you the email with a magic link",
            "sign_up_confirmation_secondary_button_title": "Resend email",
            "sign_up_confirmation_secondary_text": "Didn't you receive the email?",
            "sign_up_confirmation_text": "We sent the link to verify your email address at: {0}",
            "sign_up_confirmation_title": "Check your email"
        },
        "email_login_screen": {
            "fname_label": "First name",
            "fname_placeholder": "Your first name",
            "hint": "We will send you the email with a magic link",
            "label": "Email",
            "lname_label": "Last name",
            "lname_placeholder": "Your last name",
            "placeholder": "Your email address"
        },
        "errors": {
            "archived": "The event you tried to access is archived."
        },
        "welcome_screen": {
            "welcome_button_title": "Get started",
            "welcome_text": "Browse, connect and discover everything there is on your event",
            "welcome_title": "Welcome"
        }
    },
    "events": {
        "all_pill": "All",
        "content_hubs_title": "Content hubs",
        "future_pill": "Future",
        "future_title": "Future events",
        "no_events": "There are no events yet",
        "ongoing_pill": "Ongoing",
        "ongoing_title": "Ongoing events",
        "past_pill": "Past",
        "past_title": "Past events",
        "search_placeholder": "Search",
        "testing_pill": "Testing",
        "testing_title": "Testing events",
        "title": "My Events"
    },
    "general": {
        "agree": "Yes, please!",
        "cookies-disclaimer": "You can share anonymized usage statistics with us to improve your experience. Read our {0}.",
        "cookies-disclaimer-link": "cookie policy",
        "cookies-withdraw-link": "withdraw your consent",
        "disagree": "No, thanks!",
        "no_elements": "No elements found",
        "read_less": "read less",
        "read_more": "read more",
        "reject": "No, thanks!"
    },
    "instantiation": {
        "continue_button_title": "Continue",
        "instructions": {
            "description": "",
            "sso_text": "We have integrated your event application with external service, so you can use your email and password to join your events quickly and securely",
            "sso_title": "Easier and faster",
            "title": "Welcome back"
        }
    },
    "login": {
        "account_email_description": "To access your app please enter your email.",
        "account_email_sent_description": "An email is on its way! Please check your inbox.",
        "account_login_mean": "Login with account",
        "account_send_email_activation": "Email me a magic link",
        "account_send_email_description": "Click Submit to receive an email with a login link instead.",
        "act_code": "Activation code",
        "actions": {
            "submit": "Submit"
        },
        "add_a_new_event": "Login",
        "back": "Back",
        "cancel": "Cancel",
        "cannot_change_password": "You cannot change your password now.",
        "change_password": "Change Password",
        "change_password_description": "Choose a new password for your account.",
        "change_password_label": "Change password",
        "check_email": "Check your email",
        "close": "Close",
        "confirm_password": "Confirm password",
        "cookiesDisclaimer": "This website uses cookies. By continuing to browse the site, you are agreeing to our {{link}}",
        "cookiesDisclaimerAgreeButtonLabel": "Agree",
        "cookiesDisclaimerLinkLabel": "use of cookies",
        "current_and_upcoming": "Current and upcoming events",
        "email": "Email",
        "email_and_code": "Email and Activation Code",
        "email_send_failed": "There was a problem getting your account data. Please try again later or contact our support at https://spotme.com/help",
        "email_sent": "We sent the link to verify your email address at: {0}",
        "error": {
            "auth-failed": "Sorry, you could not be authenticated.",
            "auth-missing": "Sorry, this authentication method is not available.",
            "contact": "Please use a different authentication method or contact us at {0}",
            "missing-email": "Sorry, your email address could not be retrieved.",
            "request": "Mention the following code to our support when reporting your issue: {0}"
        },
        "error-expired-token": "Your link has expired. Please request a new one using the form below.",
        "error-internal-error": "We're having a momentary issue. Please try again in a bit.",
        "error-invalid-source-network": "Please connect with your corporate VPN to access this website",
        "error-invalid-url": "Error: Invalid URL",
        "error-logout": "You have been logged out",
        "error-online-not-available": "This event is not available online. Please use the app on your mobile device.",
        "errors": {
            "1162": "This account is blocked, please contact support"
        },
        "forgotten_password": "Forgotten password?",
        "forgotten_password_description": "Did you forgot you password? Click Submit to receive an email with a link to set a new password.",
        "forgotten_password_link_not_received": "Didn't you receive the instructions?",
        "forgotten_password_resend": "Resend email",
        "forgotten_password_sent_description": "We sent an email with a link to restore your password at: {0}",
        "login_failed": "There was a problem getting your account data. Please try again later or contact our support at https://spotme.com/help",
        "login_failed_access_code": "Unable to log-in with the provided access code. Please verify upper and lower case letters, and try again, or contact our support at https://spotme.com/help.",
        "logout": "Sign out",
        "magic_link": "Our magic link will allow you to sign in quickly and securely without having to use a password",
        "magic_link_not_received": "Didn't you receive the magic link?",
        "magic_link_title": "Let's make it quick",
        "manual_login": "Login manually",
        "native_app_install_instructions": "Or download the App",
        "new_password": "New password",
        "next": "Continue",
        "no_invites": "You have no invites.",
        "old_password": "Old password",
        "password": "Password",
        "password_changed": "Your password has been changed.",
        "password_changed_email_failed": "Your password could not be changed. You might have specified a wrong current password.",
        "password_changed_token_failed": "Your password could not be changed. Your email link might have expired.",
        "password_error_no_match": "Passwords doesn't match.",
        "password_strong": "Medium",
        "password_too_weak": "Too weak",
        "password_very_strong": "Strong",
        "password_weak": "Weak",
        "past_events": "Past events",
        "qr_instructions": "Scan the QR code below with your device to activate the app or click an event in the list to access it from your browser.",
        "request_login_link": "Request a login link",
        "requesting": "Requesting...",
        "resend": "Resend link",
        "restore_password_text": "In the field below type the email address wich you have signed up, we will send you the instructions for you to restore your password.",
        "restore_password_title": "Restore password",
        "search": "Search",
        "signin_description": "To continue please provide your password.",
        "signup_confirmation_label": "A confirmation email is on its way! Please check your inbox.",
        "signup_description": "Before proceeding you will also have to set up your account password.",
        "standard_login_mean": "Login with an email",
        "start": "Get started",
        "submit": "Submit",
        "types": {
            "account_login": "Login with an account",
            "public_login": "Public login",
            "redirect": "Open page",
            "sso": "Single Sign On",
            "standard": "Email login",
            "standard_plus": "Event login"
        },
        "unknown_email": "There are no events associated with this email, please check the instructions you've received with this link.",
        "welcome_intro_message": "Let's start!",
        "welcome_message": "To access your app please enter your email.",
        "welcome_message_legacy": "To access your event app please enter your email and activation code.",
        "welcome_page_title": "Welcome",
        "wrong_provided_values": "You could not be logged with the provided information."
    },
    "registration": {
        "actions": {
            "add_to_cal": "Add to calendar",
            "back_to_reg": "Go back to registration page",
            "complete_registration": "Submit",
            "continue_registration": "Next",
            "continue_to_event": "Go to the event",
            "edit": "Edit",
            "login": "Log in",
            "logout": "Sign out",
            "open_menu": "Open menu",
            "previous_section": "Back",
            "show_less": "Show less",
            "show_less_speakers": "Hide speakers",
            "show_more": "Show more",
            "show_more_speakers": "More speakers",
            "update_registration": "Submit",
            "warning_wall_accept": "Accept",
            "warning_wall_reject": "Reject"
        },
        "base": {
            "or": "OR"
        },
        "calendar": {
            "add_to_calendar": "Add to calendar",
            "add_to_google": "Add to Google Calendar"
        },
        "form": {
            "already_registered": "Already registered?",
            "closed_text": "Registration to this event is no longer possible.",
            "closed_title": "Registration closed",
            "disclaimer_interpolation": "Find more information about data we collect in {0}.",
            "email_placeholder": "Enter your email to register",
            "fields": {
                "email": "Email address",
                "fname": "First name",
                "fp_rsvp_status": "Are you attending the event?",
                "lname": "Last name"
            },
            "labels": {
                "availability": {
                    "available": "Available for registration",
                    "available_seats": "Available seats: {0}",
                    "closed": "Registration closed",
                    "conflict": "Time conflict",
                    "cutoff_time_exceeded": "Cutoff time exceeded",
                    "exclusion": "Exclusion conflict",
                    "max_registrations_per_day_reached": "Max per day reached",
                    "save_join": "Submit to join the waitlist",
                    "save_leave": "Submit to leave the waitlist",
                    "save_leave_tip": "If a seat becomes available you will be notified.",
                    "session_full": "Session full",
                    "unkown_error": "Unkown error",
                    "waitlist_signed_up": "Signed up to the waitlist"
                },
                "subscribe_to_waitlist": "Join waitlist",
                "unsubscribe_from_waitlist": "Leave waitlist",
                "view_details": "View details"
            },
            "messages": {
                "generic_error": "An error occurred. Try again and if the problem persists please contact the support.",
                "not_min_registered_sessions": "Please select {0} session to register successfully.|Please select {0} sessions to register successfully.",
                "payment_failed": "An error occurred during ticket purchasing step. Please try again or contact the support.",
                "payment_time_expired": "Your ticket purchasing time has expired. Please try again or contact the support.",
                "selection_outdated": "The sessions you selected are no longer available. Please review your selection and try again.",
                "some_errors_occurred": "Unfortunately the selected session is not available anymore. Please take a moment to review your choices and make an alternative selection.",
                "user_limit_exceeded": "Unfortunately we've reached the maximum capacity for registrations, and we can no longer accept new registrations at this time."
            },
            "options": {
                "fp_rsvp_status": {
                    "no": "No, I will not attend",
                    "yes": "Yes, I will attend"
                }
            },
            "private_reg_forbidden_text": "Sorry, it seems you are not allowed to register to this event. In case you have any questions or need additional help please contact {0}.",
            "private_reg_forbidden_text_no_link": "Sorry, it seems you are not allowed to register to this event. In case you have any questions or need additional help please contact support.",
            "private_reg_support": "SpotMe Support",
            "retrieve_btn_text": "Retrieve registration",
            "retrieve_reg_placeholder": "Enter your email",
            "retrieve_your_reg": "Retrieve your registration",
            "sso_button_title": "Register with your {0}",
            "sso_login_button_title": "Login with your {0}",
            "submissions": {
                "accepted": {
                    "title": "You have registered successfully."
                },
                "check_inbox": "Please check your inbox.",
                "continue_to_branding": "Login to your account to continue.",
                "declined": {
                    "title": "You are not attending this event."
                },
                "email_message": "We have sent you a confirmation email. If the email is not in your inbox, please check your Junk or Spam folders.",
                "registered": {
                    "title": "You have registered successfully."
                },
                "requested": {
                    "title": "Thank you for your registration! It is being reviewed."
                },
                "summary": {
                    "title": "You are registered to the following sessions"
                },
                "summary_waitlisted": {
                    "title": "You are signed up to the following waitlist sessions"
                },
                "updated": {
                    "title": "You have updated your data successfully."
                },
                "waiting": {
                    "title": "Thank you for your registration, you have been added to the waiting list."
                }
            },
            "submit": "Register now",
            "title": "Register now",
            "titles": {
                "main": "Complete your profile"
            },
            "updates_not_allowed": "You can no longer submit updates to your profile.",
            "user_limit_reached_text": "We've reached the maximum capacity for registrations and we can no longer accept new registrations at this time.",
            "user_limit_reached_title": "Registration capacity reached",
            "waiting_list_title": "Register for the waiting list",
            "waiting_list_warning": "The registration capacity has been reached. Please register to be added to the event waiting list."
        },
        "modals": {
            "login": {
                "description": "Type your email and we will send you a link to log in.",
                "email_placeholder": "Enter your email address",
                "email_sent_text": "We have sent you an email with an access link for the event.",
                "email_sent_title": "Check your email",
                "fname_placeholder": "Enter your first name",
                "lname_placeholder": "Enter your last name",
                "submit": "Log in",
                "title": "Already registered to the event?"
            }
        },
        "pin": {
            "did_not_receive": "Did you not receive the email?",
            "email_sent_again": "Email was sent again",
            "email_sent_error": "There was an error sending the email. Please try again, or contact the support.",
            "error": "Incorrect code. Please try again.",
            "send_again": "Send again",
            "title": "Thank you for your registration request",
            "description": "If this email is already associated with a registration, we’ve sent an email with an access code to retrieve the existing registration."
        },
        "sections": {
            "description": {
                "title": "Description"
            },
            "schedule": {
                "title": "Schedule"
            },
            "speakers": {
                "title": "Speakers"
            },
            "sponsors": {
                "title": "Sponsors"
            }
        },
        "sessions": {
            "closed": "Some of the sessions on your current selection are not opened for registration. Please review your selection and try again.",
            "conflict": "Some of the session conflicts with your current selection. Please review your selection and try again.",
            "cutoff_time_exceeded": "Changes are not allowed this close to the start time of the session.",
            "exclusion": "Some of the session conflicts with your current selection. Please review your selection and try again.",
            "max_registrations_per_day_reached": "You have reached the maximum number of registrations for this day.",
            "max_registrations_total_reached": "You have reached the maximum number of registrations.",
            "session_full": "Some of the sessions you selected are full. Please review your selection and try again.",
            "unregister_disabled": "You can no longer unregister from this session."
        },
        "to_date": "to",
        "update_profile": {
            "call_to_action": "Update your profile",
            "rsvp_question": "Will you be attending?",
            "update_intro": "Update your registration data."
        },
        "welcome_back": "Welcome back",
        "welcome_back_user": "Welcome {0}"
    },
    "store": {
        "apple": {
            "app-store-install": "Install app from Apple Store"
        },
        "google": {
            "play-store-install": "Install app from Play Store"
        }
    },
    "validations": {
        "messages": {
            "alpha": "The {_field_} field may only contain alphabetic characters",
            "alpha_dash": "The {_field_} field may contain alpha-numeric characters as well as dashes and underscores",
            "alpha_num": "The {_field_} field may only contain alpha-numeric characters",
            "alpha_spaces": "The {_field_} field may only contain alphabetic characters as well as spaces",
            "between": "The {_field_} field must be between {min} and {max}",
            "confirmed": "The {_field_} field confirmation does not match",
            "digits": "The {_field_} field must be numeric and exactly contain {length} digits",
            "dimensions": "The {_field_} field must be {width} pixels by {height} pixels",
            "double": "The {_field_} field must be a valid decimal",
            "email": "The {_field_} field must be a valid email",
            "email_domain": "This email address is not allowed",
            "excluded": "The {_field_} field is not a valid value",
            "ext": "The {_field_} field is not a valid file",
            "image": "The {_field_} field must be an image",
            "integer": "The {_field_} field must be an integer",
            "length": "The {_field_} field must be {length} long",
            "max": "The {_field_} field may not be greater than {length} characters",
            "max_value": "The {_field_} field must be {max} or less",
            "mimes": "The {_field_} field must have a valid file type",
            "min": "The {_field_} field must be at least {length} characters",
            "min_value": "The {_field_} field must be {min} or more",
            "numeric": "The {_field_} field may only contain numeric characters",
            "oneOf": "The {_field_} field is not a valid value",
            "regex": "The {_field_} field format is invalid",
            "required": "this field is required",
            "required_if": "The {_field_} field is required",
            "session_required": "Please select at least one session",
            "size": "The {_field_} field size must be less than {size}KB"
        },
        "names": {
            "fname": "first name"
        }
    }
}
